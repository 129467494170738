import { Button, Card, CardActions, CardContent, Container, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useFirebaseApp, useUser } from "reactfire";
import Logout from "./Components/Logout"

const useStyles = makeStyles(theme => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        columnGap: theme.spacing(4)
    },
    flex: {
        justifyContent: "space-between"
    }
}));

const Profile = () => {

    const { data: user } = useUser();
    const firebase = useFirebaseApp();
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    // TODO: User Deletion only works if log in happened recently

    const deleteUser = (() => {
        firebase.auth().currentUser.delete().then(() => {
            console.debug("FIREBASE: delete request");
            history.push("/signup");
        }).catch(() => {
            console.log("User was not deleted");
        })
    });

    return (
        <Container>
            {user.isAnonymous
                ?
                <Card>
                    <CardContent>
                        <Typography variant="h5">{t("logged in anonymously")}</Typography>
                    </CardContent>
                    <CardActions>
                        <Logout />
                    </CardActions>
                </Card>
                :
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h4">{t("profile")}</Typography>
                        <div className={classes.grid}>
                            <Typography variant="body1">{t("name") + ":"}</Typography>
                            <Typography variant="body1">{user.displayName}</Typography>
                            <Typography variant="body1">{t("email") + ":"}</Typography>
                            <Typography variant="body1">{user.email}</Typography>
                        </div>
                    </CardContent>
                    <CardActions className={classes.flex}>
                        <Logout />
                        <Button color="primary" variant="outlined" onClick={deleteUser}>{t("del")}</Button>
                    </CardActions>
                </Card>
            }
        </Container>
    );
}

export default Profile;
import { Button, CardContent, makeStyles, TextField } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { MyPaper } from "Globals/Components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useFirestore, useUser } from "reactfire";

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: "1rem"
    },

    flex: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        columnGap: theme.spacing(2),
    },

    button: {
      marginBottom: "6px",
      padding: "4px",
      minWidth: "initial"
    }
}));

const Createlist = (props) => {

    // Data
    const { collection_lists, collection_users_user_used } = props
    const fieldValue = useFirestore.FieldValue;

    // Hooks
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { data: user } = useUser();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    // Create new list in lists and add to used lists for user. Then navigate to the created list.
    const createList = (e) => {
        e.preventDefault()
        if (title) {
            collection_lists.add({
                title: title,
                description: description,
                creationtime: fieldValue.serverTimestamp(),
                creator: {uid: user.uid, displayName: user.displayName},
                users: [{uid: user.uid, displayName: user.displayName}]
            }).then((docRef) => {
                console.debug("FIREBASE: set request")
                collection_users_user_used.doc(docRef.id).set({
                    title: title,
                    description: description,
                    creationtime: fieldValue.serverTimestamp(),
                    creator: {uid: user.uid, displayName: user.displayName},
                    users: [{uid: user.uid, displayName: user.displayName}],
                    lastaccesstime: fieldValue.serverTimestamp(),
                    listid: docRef.id
                }).then(() => {
                    console.debug("FIREBASE: set request")
                });
                history.push("/lists/" + docRef.id)
            });
            setTitle("");
            setDescription("");
        }
    };

    return (
        <MyPaper>
            <CardContent>
                <form className={classes.flex} onSubmit={createList}>
                    <div>
                        <div className={classes.flex}>
                            <TextField inputProps={{tabindex: "1"}} className={classes.input} fullWidth value={title} onChange={(e) => setTitle(e.target.value)} label={t("list title")}
                                InputProps={{
                                    endAdornment:
                                        <Button tabindex="3" className={classes.button} type="submit" variant="contained" color="primary">
                                            <ArrowForward fontSize="small"/>
                                        </Button>
                                }} />

                        </div>
                        <TextField inputProps={{tabindex: "2"}}  className={classes.input} rows="1" rowsMax="3" value={description} onChange={(e) => setDescription(e.target.value)} multiline fullWidth label={t("description")} />
                    </div>
                </form>
            </CardContent>
        </MyPaper>
    );
}

export default Createlist;
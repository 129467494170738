
import { makeStyles } from "@material-ui/styles";
import { MyPaper, MySnackbar } from "Globals/Components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirestore} from "reactfire";
import OverviewListHeader from "../Subcomponents/OverviewListHeader";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        maxHeight: "100%",
        overflowY: "auto"
    },

    list: {
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: "10px"
    }
}));

const Usedlists = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const firestore = useFirestore();


    const { data_users_user_used } = props


    // snackbar handling
    const [snackbar, setSnackbar] = useState({ open: false, displayMessage: "", severity: "success" });

    // delete list function passed to overview list header, binded with data there
    const deleteList = (ref1, ref2, title) => {
        let batch = firestore.batch();
        batch.delete(ref1)
        batch.delete(ref2)
        batch.commit()
            .then(() => {
                setSnackbar({ open: true, displayMessage: t("deleted", { title: title }), severity: "success" })
            })
            .catch((error) => {
                setSnackbar({ open: true, displayMessage: error.message, severity: "error" })
            })
    }

    // remove list from displayed lists
    const removeList = (ref1, title) => {
        let batch = firestore.batch();
        batch.delete(ref1)
        batch.commit()
            .then(() => {
                setSnackbar({ open: true, displayMessage: t("removed", { title: title }), severity: "success" })
            })
            .catch((error) => {
                setSnackbar({ open: true, displayMessage: error.message, severity: "error" })
            })
    }

    // return if user is logged in
    return (
        <div className={classes.root}>
            <div className={classes.list}>

                {
                    data_users_user_used.map((data_users_user_used_id) => {


                        return <MyPaper key={data_users_user_used_id.id}>

                            <OverviewListHeader
                                data_users_user_used_id={data_users_user_used_id}
                                deleteList={deleteList}
                                removeList={removeList}
                                {...props}
                            />

                        </MyPaper>
                    })
                }
            </div>
            <MySnackbar displayMessage={snackbar.displayMessage} open={snackbar.open} onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} />
        </div>
    )
}


export default Usedlists;
import { Container, div, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MyCopyright } from "Globals/Components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        background: "#424242",
        color: "#eeeeee",
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10)
    },

    copyrightRoot: {
        background: "#212121",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        color: "#eeeeee",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    link: {
        color: "#eeeeee",
        textDecoration: "none",
        display: "block"
    },

    grid: {
        display: "grid",
        columnGap: "20px",
        rowGap: "40px",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        justifyContent: "center",

        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr 1fr"
        }
    },


}));

const Footer = () => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>


            <div className={classes.root}>
                <Container>
                    <div className={classes.grid}>
                        <div item xs={6} sm={3}>
                            <Typography variant="h6">{t("footer_links")}</Typography>
                            <Typography variant="body1" className={classes.link} component={Link} to="/">{t("home")}</Typography>
                            <Typography variant="body1" className={classes.link} component={Link} to="/overview">{t("overview")}</Typography>
                            <Typography variant="body1" className={classes.link} component={Link} to="/profile">{t("profile")}</Typography>
                        </div>
                        <div item xs={6} sm={3}>
                            <Typography variant="h6">{t("footer_contact")}</Typography>
                            <Typography variant="body1">{t("footer_email")}</Typography>
                        </div>
                        <div item xs={6} sm={3}>
                        <Typography gutterBottom variant="h6">{t("footer_support")}</Typography>
                        <Button variant="contained" color="secondary" href="https://www.buymeacoffee.com/limejuicestudio">{t("footer_bmac")}</Button>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.copyrightRoot}>
                <MyCopyright style={{color: "#eeeeee"}}/>
            </div>
        </div>
    );
}

export default Footer;
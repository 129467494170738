import { Typography, Stepper, Step, StepLabel, StepConnector } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/styles"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        columnGap: theme.spacing(10),
        rowGap: theme.spacing(10)
    },

    gridBlock: {
        margin: theme.spacing(0),
        position: "relative",
        overflow: "visible"
    },

    header: {
        marginTop: theme.spacing(4),
        display: "grid",
        gridTemplateColumns: "1fr auto"
    },

    header1: {
        justifySelf: "center"
    },

    tagBorder: {
        borderWidth: "4px",
        borderStyle: "solid",
        borderColor: "red",
        borderRadius: "5px",
        fontWeight: "bold",
        color: "red",
        transform: "translateX(0px) translateY(0px) rotateZ(35deg)",
    },

    tagText: {
        padding: theme.spacing(2)
    },

    icon: {
        backgroundImage: "linear-gradient(45deg, #F57C00, #FFCC80)",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white"
    },

    noBackground: {
        background: "None"
    }
}));

const CustomStepConnector = withStyles(theme => ({
    vertical: {
        padding: 0,
        marginLeft: "18px",
    },

    lineVertical: {
        minHeight: "24px",
        width: "4px",
        border: "None",
        backgroundImage: "None",
    }

}))(StepConnector);


function CustomStepIcon(props) {
    const classes = useStyles();
    return (
        <div className={classes.icon}>
            <Typography variant="h6">{props.icon}</Typography>
        </div>
    )
}


const HowItWorks = () => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.gridBlock}>
                    <div className={classes.header}>
                        <div className={classes.header1}>
                            <Typography variant="h5" className={classes.header1}>{t("how to create")}</Typography>
                        </div>
                        <div className={classes.tagBorder}>
                            <Typography align="center" variant="h5" className={classes.tagText}>{t("how to create tag")}</Typography>
                        </div>
                    </div>
                    <Stepper className={classes.noBackground} orientation="vertical" connector={<CustomStepConnector />} activeStep={-1}>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to create 1")}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to create 2")}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to create 3")}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to create 4")}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
                <div className={classes.gridBlock}>
                    <div className={classes.header}>
                        <div className={classes.header1}>
                            <Typography variant="h5" className={classes.header1}>{t("how to share")}</Typography>
                        </div>
                        <div className={classes.tagBorder}>
                            <Typography align="center" variant="h5" className={classes.tagText}>{t("how to share tag")}</Typography>
                        </div>
                    </div>
                    <Stepper className={classes.noBackground} orientation="vertical" connector={<CustomStepConnector />} activeStep={-1}>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to share 1")}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to share 2")}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
                <div className={classes.gridBlock}>
                    <div className={classes.header}>
                        <div className={classes.header1}>
                            <Typography variant="h5" className={classes.header1}>{t("how to open")}</Typography>
                        </div>
                        <div className={classes.tagBorder}>
                            <Typography align="center" variant="h5" className={classes.tagText}>{t("how to open tag")}</Typography>
                        </div>
                    </div>
                    <Stepper className={classes.noBackground} orientation="vertical" connector={<CustomStepConnector />} activeStep={-1}>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to open 1")}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                {t("how to open 2")}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
import { Avatar, Button, Collapse, Container, makeStyles, TextField, Typography, Link, Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFirebaseApp } from "reactfire";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ForgotPw = () => {

    const firebase = useFirebaseApp();
    const classes = useStyles();
    const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [error, setError] = useState("");
    const [severity, setSeverity] = useState("error")

    // Copyright disclaimer function
    function Copyright() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" component={RouterLink} to="/">
                    {t("ShareLists")}
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                console.log("reset sent")
                setError(t("reset sent", {email: email}))
                setSeverity("success");
                setEmail("");
            })
            .catch(error => {
                setError(error.message);
                setSeverity("error")
            })
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
        setError("");
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("reset password")}
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField margin="normal" required autoFocus fullWidth variant="outlined" label={t("email")} onChange={handleEmail}>
                    </TextField>
                    <Collapse in={error}>
                        <Alert variant="filled" severity={severity}>{error}</Alert>
                    </Collapse>
                    <Button type="submit" className={classes.submit} fullWidth color="primary" variant="contained">{t("reset")}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link variant="body2" component={RouterLink} to="/signin">
                                {t("signin")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link variant="body2" component={RouterLink} to="/signup">
                                {t("no account")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default ForgotPw;
import React from 'react';
import { useFirebaseApp } from 'reactfire';
import 'firebase/auth'
import { Button } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';

const Logout = () => {
  // Import firebase
  const firebase = useFirebaseApp();

  const {t} = useTranslation();
  const history = useHistory();

  // Log out function
  const handleClick = () => {
    firebase.auth().signOut().then(() => {
      history.push("/signin");
    });
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClick}>{t("logout")}</Button>
    </>
  )
};

export default Logout;

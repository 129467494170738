import { useTheme } from "@material-ui/core";
import { MyCenter, MyChip, MyPaper } from "Globals/Components";
import { useTranslation } from "react-i18next";
import { useUser } from "reactfire";

const InfoBox = (props) => {

    const { data: user } = useUser();
    const { t } = useTranslation();
    const theme = useTheme();
    let displayName = ({ text: user.displayName, color: theme.palette.primary.main });

    
    if (user.isAnonymous) {
        displayName= { text: t("anonymous"), color: theme.palette.secondary.main }
    }


    return (
        <MyPaper style={{ height: "100%" }}>
            <MyCenter>
                <div>
                    <MyChip text={displayName.text} color={displayName.color} />
                </div>
            </MyCenter>
        </MyPaper>
    );
}

export default InfoBox;
import { Container } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Typography } from "@material-ui/core"
import cloud from "img/cloud.svg"
import devices from "img/devices.svg"
import pwa from "img/pwa.svg"
import realtime from "img/realtime.svg"
import share from "img/share.svg"
import Footer from "./Components/Footer"
import FeatureBlock from "./Components/FeatureBlock"
import FeatureColumn from "./Components/FeatureColumn"
import HowItWorks from "./Components/HowItWorks"
import FAQ from "./Components/FAQ"


const useStyles = makeStyles((theme) => ({
    marginContainer: {
        position: "relative",
        paddingTop: "100px",
        paddingBottom: "100px",
    },

    backgroundColor: {
        backgroundColor: "#fafafa"
    },

    backgroundColor2: {
        backgroundColor: "#eeeeee"
    },

    sectionHeader: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: "50%",
        borderRadius: "200px",
        padding: "20px 30px",
        overflowWrap: "initial",
        wordWrap: "initial"
    }

}));

const Home = () => {

    const { t } = useTranslation();
    const classes = useStyles();

    const FeatureBlocks =
        <div>
            <FeatureBlock
                image={share}
                header={t("landing_share_header")}
                text={t("landing_share_text")}
                text2={t("landing_share_text2")} />
            <FeatureBlock
                image={devices}
                header={t("landing_devices_header")}
                text={t("landing_devices_text")}
                text2={t("landing_devices_text2")}
                reverse />
            <FeatureBlock
                image={realtime}
                header={t("landing_realtime_header")}
                text={t("landing_realtime_text")}
                text2={t("landing_realtime_text2")} />
            <FeatureBlock
                image={pwa}
                header={t("landing_pwa_header")}
                text={t("landing_pwa_text")}
                text2={t("landing_pwa_text2")}
                reverse />
            <FeatureBlock
                image={cloud}
                header={t("landing_cloud_header")}
                text={t("landing_cloud_text")}
                text2={t("landing_cloud_text2")}
            />
        </div>

    return (
        <div>
            <div>
                <Container className={classes.marginContainer}>
                    <FeatureColumn />
                </Container>
            </div>
            <div className={classes.backgroundColor}>
                <Typography align="center" variant="h4" className={`${classes.sectionHeader} ${classes.backgroundColor}`}>{t("home section1")}</Typography>
                <Container className={classes.marginContainer}>
                    {FeatureBlocks}
                </Container>
            </div>
            <div>
                <Typography align="center" variant="h4" className={`${classes.sectionHeader} ${classes.backgroundColor2}`}>{t("home section2")}</Typography>
                <Container className={classes.marginContainer}>
                    <HowItWorks />
                </Container>
            </div>
            <div className={classes.backgroundColor}>
                <Typography align="center" variant="h4" className={`${classes.sectionHeader} ${classes.backgroundColor}`}>{t("home section3")}</Typography>
                <Container className={classes.marginContainer}>
                    <FAQ />
                </Container>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
import { useTranslation } from 'react-i18next';
import './Bouncer.css';

const Bouncer = () => {

    const { t } = useTranslation();

    return (
        <div className="bouncer">
            <div>
                <div className="bouncer-frame">
                    <div className="bouncer-balls">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className="bouncer-text">
                    <div>{t("loading", "Loading...")}</div>
                </div>
            </div>
        </div>
    );
}

export default Bouncer;
import { makeStyles, Button, useTheme, useMediaQuery } from "@material-ui/core";
import {  GridOverlay } from "@material-ui/data-grid";
import { MyCenter, MyChip, MyDataGrid, MyPaper, MySnackbar } from "Globals/Components";
import { useTranslation } from "react-i18next";
import {  useFirestore, useUser } from "reactfire";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {  firebaseHowManyDaysAgo} from "Globals/Functions";
import { useEffect } from "react";
import { useState } from "react";
import { MyToolbar } from "Lists/Subcomponents/MyToolbar";
import empty_cart from "img/empty_cart.svg"

const useStyles = makeStyles(theme => ({
    overflow: {
        overflow: "hidden"
    }
}));

const Items = (props) => {

    // general needed stuff
    const classes = useStyles();
    const { t } = useTranslation();
    const { data: user } = useUser();
    const theme = useTheme();
    const fieldValue = useFirestore.FieldValue;
    const firestore = useFirestore();
    const isVerySmall = useMediaQuery(theme.breakpoints.only("xs"))
    const isSmall = useMediaQuery(theme.breakpoints.only("sm"))
    const isMedium = useMediaQuery(theme.breakpoints.only("md"))
    const isLarge = useMediaQuery(theme.breakpoints.up("lg"))

    // extraction from props
    const { collection_lists_id_items, data_lists_id_items, hideSize } = props;

    // get array of ids of checked items
    const getSelectedIds = (dataset) => {
        let result = dataset
            .filter((data) => data.checked)
            .map((data) => data.id);
        return result
    }

    const getItemsFromIds = (items, ids) => {
        let result = items.filter((item) => ids.includes(item.id))
        return result
    }

    // Calculate symmetric difference between two sets, i.e. all changes items
    const getSymmetricDifference = (arr1, arr2) => {
        let changes = arr1.filter(id => !arr2.includes(id))
            .concat(arr2.filter(id => !arr1.includes(id)));
        return changes
    }

    // checkbox selection states, before and after change to calculate delta
    const [checkboxSelection, setCheckboxSelection] = useState(getSelectedIds(data_lists_id_items));
    const [checkboxDeltaHelper, setCheckboxDeltaHelper] = useState(checkboxSelection);

    // function fired when selection changes
    const updateFirebaseSelection = (checkboxSelection) => {
        setCheckboxSelection(checkboxSelection)
        let diff = getSymmetricDifference(checkboxSelection, checkboxDeltaHelper);
        let diffItems = getItemsFromIds(data_lists_id_items, diff);
        let batch = firestore.batch();

        diffItems.forEach(item => {
            batch.set(collection_lists_id_items.doc(item.id), {
                ...item,
                checked: !item.checked,
                changetime: fieldValue.serverTimestamp(),
                user: {uid: user.uid, displayName: user.displayName}
            })
        });

        batch.commit().then(() => {
            console.debug("FIREBASE: update batch request")
        }).catch((e) => {
            doSnackbar(e.message)
        })


        setCheckboxDeltaHelper(checkboxSelection)
    };


    // edited cells handling
    const editCell = (edit) => {

        let item = data_lists_id_items.filter((item) => item.id === edit.id)
        item[edit.field] = edit.value

        collection_lists_id_items.doc(edit.id).update({
            ...item,
            changetime: fieldValue.serverTimestamp(),
            user: {uid: user.uid, displayName: user.displayName}
        }).then(() => {
            console.debug("FIREBASE: update request")
        }).catch((e) => {
            doSnackbar(e.message)
        })
    }


    // alert that is shown when no items are in the list
    const NoItemsAlert = (props) => {
        return (
            <GridOverlay>

            <MyCenter>
                <img  style={{height: "50%"}} src = {empty_cart} alt="shopping cart"/>

            </MyCenter>
                    </GridOverlay>
        )
    }

    // delete item button in datagrid functionality
    const deleteItem = (event, cellValues) => {
        event.stopPropagation();
        collection_lists_id_items.doc(cellValues.id).delete()
            .then(() => {
                console.debug("Firebase: delete request")
            }).catch((e) => {
                doSnackbar(e.message)
            });
    };

    // delete all functionality for toolbar button
    const deleteAll = () => {
        let batch = firestore.batch();

        data_lists_id_items.forEach(item => {
            batch.delete(collection_lists_id_items.doc(item.id))
        });

        batch.commit().then(() => {
            console.debug("FIREBASE: delete batch request")
        }).catch((e) => {
            doSnackbar(e.message)
        })
    };

    // delete selected items
    const deleteSelected = () => {
        let batch = firestore.batch();

        let selected = data_lists_id_items.filter((item) => item.checked)

        if (selected.length === 0) {
            doSnackbar(t("no items selected"))
        }

        selected.forEach(item => {
            batch.delete(collection_lists_id_items.doc(item.id))
        });

        batch.commit().then(() => {
            console.debug("FIREBASE: delete batch request")
        }).catch((e) => {
            doSnackbar(e.message)
        })
    }

    // Error Snackbar handling
    const [snackState, setSnackState] = useState();
    const [error, setError] = useState();
    const displaySnackbar = () => {
        setSnackState(!snackState)
    }
    const doSnackbar = (message) => {
        setError(message);
        displaySnackbar();
    }

    // column visibility based on display size
    const [displayedColumns, setDisplayedColumns] = useState({
        item: true,
        comment: false,
        creator: false,
        creationtime: false,
        creationtime_2: false,
        user: false,
        changetime: false,
        changetime_2: false,
        delete: true
    });
    useEffect(() => {
        if(isVerySmall){
            setDisplayedColumns({
                item: true,
                comment: false,
                creator: false,
                creationtime: false,
                creationtime_2: false,
                user: false,
                changetime: false,
                changetime_2: false,
                delete: true
            })
        } else if(isSmall){
            setDisplayedColumns({
                item: true,
                comment: false,
                creator: false,
                creationtime: true,
                creationtime_2: false,
                user: false,
                changetime: false,
                changetime_2: false,
                delete: true
            })
        } else if (isMedium){
            setDisplayedColumns({
                item: true,
                comment: false,
                creator: true,
                creationtime: true,
                creationtime_2: false,
                user: false,
                changetime: false,
                changetime_2: false,
                delete: true
            }) 
        } else if (isLarge) {
            setDisplayedColumns({
                item: true,
                comment: true,
                creator: true,
                creationtime: true,
                creationtime_2: false,
                user: false,
                changetime: false,
                changetime_2: false,
                delete: true
            }) 
        }
    },[isVerySmall, isSmall, isMedium, isLarge])

    // columns for datagrid
    const columns = [
        {
            field: 'item',
            headerName: t("items item"),
            flex: 2,
            minWidth: 50,
            editable: true,
            hide: !displayedColumns.item
        },

        {
            field: "comment",
            headerName: t("items comment"),
            editable: true,
            flex: 2,
            minWidth: 50,
            hide: !displayedColumns.comment
        },

        {
            field: 'creator',
            headerName: t("items username"),
            width: 200,
            editable: false,
            hide: !displayedColumns.creator,
            valueGetter: (params) => params.value.displayName || t("items anonymous"),
            renderCell: (params) => <MyChip text={params.value} 
                color={params.row.creator.displayName ? theme.palette.primary.main : theme.palette.secondary.main} />
        },

        {
            field: 'creationtime',
            headerName: t("items creationtime"),
            minWidth: 100,
            editable: false,
            disableColumnMenu: true,
            filterable: false,
            flex: 1,
            hide: !displayedColumns.creationtime,
            valueFormatter: (params) => firebaseHowManyDaysAgo(params.row.creationtime, t).text,
            renderCell: (params) => <MyChip {...firebaseHowManyDaysAgo(params.row.creationtime, t)} />
        },

        {
            field: 'creationtime_2',
            headerName: t("items creationtime 2"),
            width: 200,
            editable: false,
            disableColumnMenu: true,
            filterable: false,
            hide: !displayedColumns.creationtime_2,
            valueGetter: (params) => params.row.creationtime,
            valueFormatter: (params) => firebaseHowManyDaysAgo(params.row.creationtime, t).time,
            renderCell: (params) => {
                const { time, color } = firebaseHowManyDaysAgo(params.row.creationtime, t);
                return (
                    <MyChip text={time} color={color} />
                )
            }
        },

        {
            field: "user",
            headerName: t("items changeuser"),
            width: 200,
            editable: false,
            disableColumnMenu: true,
            filterable: false,
            hide: !displayedColumns.user,
            valueGetter: (params) => params.value.displayName || t("items anonymous"),
            renderCell: (params) => <MyChip text={params.value} 
                color={params.row.user.displayName ? theme.palette.primary.main : theme.palette.secondary.main} />
        },

        {
            field: "changetime",
            headerName: t("items changetime"),
            width: 200,
            editable: false,
            disableColumnMenu: true,
            filterable: false,
            hide: !displayedColumns.changetime,
            valueFormatter: (params) => firebaseHowManyDaysAgo(params.row.changetime, t).text,
            renderCell: (params) => <MyChip {...firebaseHowManyDaysAgo(params.value, t)} />
        },

        {
            field: 'changetime_2',
            headerName: t("items changetime 2"),
            width: 200,
            editable: false,
            hide: !displayedColumns.changetime_2,
            disableColumnMenu: true,
            filterable: false,
            valueGetter: (params) => params.row.changetime,
            valueFormatter: (params) => firebaseHowManyDaysAgo(params.row.changetime, t).time,
            renderCell: (params) => {
                const { time, color } = firebaseHowManyDaysAgo(params.row.changetime, t);
                return (
                    <MyChip text={time} color={color} />
                )
            }
        },

        {
            field: "delete",
            headerName: t("items delete"),
            width: 75,
            headerAlign: "center",
            hide: !displayedColumns.delete,
            disableSort: true,
            disableColumnMenu: true,
            filterable: false,
            editable: false,
            disableExport: true,
            hideSortIcons: true,
            renderCell: (params) => {
                return (
                    <MyCenter>
                        <Button onClick={(event) => deleteItem(event, params)}>
                            <DeleteOutlineIcon />
                        </Button>
                    </MyCenter>
                );
            }
        }
    ];

    return (
        <MyPaper className={classes.overflow}>

            <MyDataGrid
                rows={data_lists_id_items}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                selectionModel={checkboxSelection}
                onSelectionModelChange={(newSelectionModel) => {
                    updateFirebaseSelection(newSelectionModel)
                }}
                onCellEditCommit={(commit) => editCell(commit)}
                components={{
                    Toolbar: MyToolbar,
                    NoRowsOverlay: NoItemsAlert
                }}
                componentsProps={{
                    toolbar: {
                        clickButton1: () => deleteAll(), 
                        clickButton2: () => deleteSelected(),
                        hideSize: hideSize
                    }
                }}
                onColumnVisibilityChange={(event) => {
                    let temp = displayedColumns
                    temp[event.field] = event.isVisible
                    setDisplayedColumns(temp)
                }}
                hideFooter = {data_lists_id_items.length > 100 ? false : true} //only show footer when more than 100 items
            />

            <MySnackbar open={snackState} onClose={displaySnackbar} severity="error" displayMessage={error} />
        </MyPaper>

    );
}

export default Items;
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/styles";
import { Chip, Paper, Snackbar, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';


export const MyPaper = withStyles(theme => ({
    root: {
        margin: "0px",

        [theme.breakpoints.down("xs")]: {
            margin: "0px 0px"
        }
    }
}))(Paper);

export const MyCenter = (props) => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
        }} {...props}>
        </div>
    )
};

export const MyChip = (props) => {

    const theme = useTheme();

    let color = props.color

    if (props.color === "primary") {
        color = theme.palette.primary.main
    } else if (props.color === "secondary") {
        color = theme.palette.secondary.main
    }

    let NewChip = withStyles(theme => ({
        root: {
            backgroundColor: color,
            color: theme.palette.primary.contrastText
        }
    }))(Chip);

    return (
        <NewChip size={props.size} label={props.text}></NewChip>
    )
}

export const MyDataGrid = withStyles({
    root: {
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'None !important',
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'None !important',
        }
    }
})(DataGrid);

export const MySnackbar = (props) => {
    return (
        <Snackbar open={props.open} autoHideDuration={6000} onClose={props.onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert severity={props.severity} variant="filled" onClose={props.onClose}>
                {props.displayMessage}
            </Alert>
        </Snackbar>
    )
}

export const MyCopyright = (props) => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" component={RouterLink} to="/">
                <Typography {...props}>
                {'Copyright © Sharelists'}{' '}{new Date().getFullYear()}
                </Typography>
            </Link> 
        </Typography>
    );
};





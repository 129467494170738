import Navbar from "./Navbar/Navbar";
import Home from "./Home/Home";
import Overview from "./Overview/Overview";
import "firebase/firestore";
import "firebase/auth";
import { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Lists from './Lists/Lists';
import { AuthCheck, useFirestore } from 'reactfire';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { amber, blue } from '@material-ui/core/colors';
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignIn/SignUp";
import Profile from "./Profile/Profile";
import ForgotPw from "./SignIn/ForgotPw";

const theme = createMuiTheme({
  overrides: {
    MuiCard: {
      root: {
        margin: "10px",
      }
    },
  },
  spacing: 4,
  palette: {
    primary: {
      main: amber[700],
      contrastText: "#ffffff"
    },
    secondary: {
      main: blue[500],
    },
  },
});

function App() {

  const firestore = useFirestore();

  useEffect(() => {
    firestore.enablePersistence()
      .then(() => {
        console.debug("FIREBASE: Persistence enabled")
      });
    // eslint-disable-next-line 
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Navbar />

            <Suspense>
              <Switch>

                <Route exact path="/">
                  <Home />
                </Route>

                <Route path="/signin">
                  <SignIn/>
                </Route>

                <Route path="/signup">
                  <SignUp/>
                </Route>
                
                <Route path="/forgotpw">
                  <ForgotPw/>
                </Route>
                
                <Route path="/overview">
                  <AuthCheck fallback={<SignIn/>}>
                    <Overview />
                  </AuthCheck>
                </Route>

                <Route path="/lists/:listId">
                  <AuthCheck fallback={<SignIn/>}>
                    <Lists />
                  </AuthCheck>
                </Route>

                <Route path="/profile">
                  <AuthCheck fallback={<SignIn/>}>
                    <Profile/>
                  </AuthCheck>
                </Route>  

              </Switch>
            </Suspense>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;

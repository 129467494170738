import { Button, makeStyles, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const useStyles = makeStyles({
    center: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    margin: {
        marginBottom: "1rem"
    },

    button: {
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)"
    }
})

const NotAvailable = () => {

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.center}>
            <Typography variant="h5" className={classes.margin}>{t("not available")}</Typography>
            <Button className={classes.button} variant="contained" color="primary" component={Link} to="/overview">{t("back")}</Button>
        </div>
    );
}

export default NotAvailable;
const Firebaseconfig = {
    apiKey: "AIzaSyAzQX9rJxioeXfdYnebsVLO69SG39wkxcI",
    authDomain: "shopping-list-pwa-4e151.firebaseapp.com",
    databaseURL: "https://shopping-list-pwa-4e151.firebaseio.com",
    projectId: "shopping-list-pwa-4e151",
    storageBucket: "shopping-list-pwa-4e151.appspot.com",
    messagingSenderId: "537159612899",
    appId: "1:537159612899:web:2157eccfcb4a5c9c9788d6",
    measurementId: "G-B8114LK9RD"
  };
 
export default Firebaseconfig;
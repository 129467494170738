import { Button, CardContent, makeStyles, TextField } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirestore, useUser } from "reactfire";
import AddIcon from '@material-ui/icons/Add';
import { MyPaper } from "Globals/Components";


const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: "6px",
        padding: "4px",
        minWidth: "initial"
    }
}));


const Createitem = (props) => {

    const {data: user} = useUser();
    const classes = useStyles();
    const firestore = useFirestore();
    const fieldValue = useFirestore.FieldValue;
    const { t } = useTranslation();
    const [item, setItem] = useState("");
    const { collection_lists_id_items } = props;

    const addItem = (e) => {
        e.preventDefault();
        if (item) {
            // Add multiple items if they are seperated by comma, otherwise single item
            let batch = firestore.batch();
            let items = item.split(",").map(item => item.trim())
            items.forEach(item => {
                if (item.length > 0) {

                    batch.set(collection_lists_id_items.doc(), {
                        item: item,
                        checked: false,
                        creator: {uid: user.uid, displayName: user.displayName},
                        creationtime: fieldValue.serverTimestamp(),
                        comment: null, // comment can be added later in datagrid
                        changetime: fieldValue.serverTimestamp(),
                        user:{uid: user.uid, displayName: user.displayName}
                    });
                }
            });
            batch.commit()
                .then(() => {
                    console.debug("FIREBASE: batch set requests")
                });
            setItem("");
        }
    };

    return (
        <MyPaper>
            <CardContent>
                <form onSubmit={addItem.bind(this)}>
                    <TextField value={item} onChange={(e) => setItem(e.target.value)} fullWidth label={t("item title")}
                        InputProps={{
                            endAdornment:
                                <Button className={classes.button} variant="contained" type="submit" name="item" color="primary">
                                    <AddIcon fontSize="small" />
                                </Button>
                        }}></TextField>
                </form>
            </CardContent>
        </MyPaper>
    );
}

export default Createitem;
import { Card, Checkbox, Button, makeStyles, Typography} from "@material-ui/core";
import { Check, NavigateNext } from "@material-ui/icons";
import { useState } from "react";


const useStyles = makeStyles(theme => ({
    item: {
        display: "grid",
        alignItems: "stretch",
        gridTemplateColumns: "auto 1fr auto",
        columnGap: theme.spacing(1),
        marginTop: "0px",
        marginBottom: theme.spacing(1),
    },

    itemCenterContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    verticalCenter: {
        display: "flex",
        alignItems: "center",
    },

    itemMeta: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        fontSize: "0.8em",
    },

    checkboxContainer: {
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },

    metaText: {
        fontSize: "0.9rem",
        color: "rgba(0, 0, 0, 0.54)"
    },

    itemDelete: {
        color: "rgba(0, 0, 0, 0.54)"
    },
}));


const DemoItem = (props) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false)

    const changeItem = (data, e) => {
        setChecked(true);
        props.changeData({...data, show: !data.show});
    };

    return (
        <Card className={classes.item} onClick={changeItem.bind(this, props.data)}>
            <div className={classes.itemCenterContent}>
                <div className={classes.checkboxContainer}>
                    <Checkbox color="secondary"
                        checkedIcon={<Check />}
                        checked = {checked}
                        onClick = {() => setChecked(!checked)}
                        disableRipple/>
                </div>
            </div>
            <div className={classes.verticalCenter}>
                <Typography variant="h6">{props.data.item}</Typography>
            </div>
            <Button className={classes.itemCenterContent}>
                <NavigateNext className={classes.itemDelete} />
            </Button>
        </Card>
    );
}

export default DemoItem;

import { Button, makeStyles, Typography, TextField, useTheme } from "@material-ui/core";
import { MyChip, MySnackbar } from "Globals/Components";
import { copyToClipboard, firebaseHowManyDaysAgo } from "Globals/Functions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useState } from "react";
import { useEffect } from "react";
import MySpeedDial from "./MySpeedDial";
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme) => ({

    root: {
        marginTop: "0px",
    },

    header: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        position: "relative",
        alignItems: "center",

    },

    border: {
        padding: "12px"
    },

    content: {
        display: "flex",
        gridTemplateColumns: "1fr 1fr",
        flexWrap: "wrap",
        columnGap: "20px",
        rowGap: "10px",
        marginTop: "10px"
    },

    statistics: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: "10px",
        rowGap: "10px"
    },

    endline: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        columnGap: "10px",
        marginTop: "10px"
    },

    clickIcons: {
        color: "#9e9e9e",
        cursor: "pointer"
    },

    allUsers: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: "4px",
        rowGap: "4px"
    },

    absolute: {
        position: "absolute",
        right: "0px"
    },

    placeholder: {
        width: "60px"
    }


}));

const OverviewListHeader = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const user = useUser();

    const { data_users_user_used_id, data_users_user_used_status, collection_users_user_used, deleteList, removeList } = props;
    const listId = data_users_user_used_id.id;

    const doc_users_user_used_id = collection_users_user_used.doc(listId);

    const doc_lists_id = useFirestore().collection("lists").doc(listId);
    const { data: data_lists_id, status: data_lists_id_status } = useFirestoreDocData(doc_lists_id, { idField: 'id' });



    props = { ...props, doc_lists_id, data_lists_id, data_lists_id_status, doc_users_user_used_id };

    // links
    const link = "/lists/" + data_users_user_used_id.id
    const fullLink = window.location.origin + link

    // check if data is available
    const [contentLoaded, setContentLoaded] = useState(false)
    useEffect(() => {
        if (data_lists_id_status === "success" && data_users_user_used_status === "success") {
            setContentLoaded(true)
        } else {
            setContentLoaded(false)
        }
    }, [data_lists_id_status, data_users_user_used_status])

    // snackbar handling
    const [snackbar, setSnackbar] = useState({ open: false, displayMessage: "", severity: "success" });
    const copyAndSnackbar = (text) => {
        copyToClipboard(fullLink)
        setSnackbar({ open: true, displayMessage: t("link copied"), severity: "success" })
    }

    // statistics data handling
    const [statisticData, setStatisticData] = useState({})
    useEffect(() => {
        if (contentLoaded) {
            let name;
            let creationtime;
            let lastaccesstime;
            let users;

            console.log("LOOK HERE", data_lists_id, user)

            // displayName
            if(data_lists_id.creator){
                name = { text: t("anonymous"), color: theme.palette.secondary.main }
                if (data_lists_id.creator.displayName === null) {
                    name = { text: t("anonymous"), color: theme.palette.secondary.main }
                } else {
                    name = { text: data_lists_id.creator.displayName, color: theme.palette.primary.main }
                }
            }

            // creationtime
            let temp = firebaseHowManyDaysAgo(data_lists_id.creationtime, t)
            creationtime = { text: temp.time, color: temp.color }

            // lastaccesstime
            temp = firebaseHowManyDaysAgo(data_users_user_used_id.lastaccesstime, t)
            lastaccesstime = { text: temp.time, color: temp.color }

            // users
            let count;
            let anonCount;
            let userNames = [];
            if(data_lists_id.users){
                count = data_lists_id.users.length
                anonCount = data_lists_id.users.filter((user) => user.displayName === null).length
                userNames = data_lists_id.users.filter((user) => user.displayName !== null).map((user) => user.displayName)
            }
            users = { count: count, anons: anonCount, names: userNames }

            // set data
            setStatisticData({ name: name, creationtime: creationtime, lastaccesstime: lastaccesstime, users: users })
        }
        // eslint-disable-next-line 
    }, [contentLoaded])


    // users of list mapping into nice Chips
    const ListUsers = (props) => {
        if (contentLoaded && statisticData.users) { // No idea why the users check is also needed but it is
            return (
                <div className={classes.allUsers}>
                    {
                        props.names.map(name => {
                            return (
                                <MyChip size="small" text={name} color="primary" />
                            )
                        })
                    }
                    {props.anons > 0
                        ?
                        <MyChip size="small" text={props.anons.toString() + "x " + t("anonymous")} color="secondary" />
                        :
                        null
                    }
                </div>
            )
        }
        return (null)
    };



    const speedDialActions = [
        { icon: <ClearIcon />, name: t("remove"), onClick: removeList.bind(null, doc_users_user_used_id, data_users_user_used_id.title) },
        { icon: <DeleteOutlineIcon />, name: t("del"), onClick: deleteList.bind(null, doc_users_user_used_id, doc_lists_id, data_users_user_used_id.title) }
    ];

    return (
        <div className={classes.root}>
            <div className={classes.border}>
                <div className={classes.header}>
                    <Typography variant="h4">{data_users_user_used_id.title}</Typography>
                    <div className={classes.placeholder} />
                    <div className={classes.absolute}>
                        <MySpeedDial {...props} actions={speedDialActions} />
                    </div>
                </div>
                <div className={classes.content}>
                    {data_users_user_used_id.description.length > 0
                        ?
                        <div>
                            <Typography>{data_users_user_used_id.description}</Typography>
                        </div>
                        :
                        null}
                    {contentLoaded
                        ?
                        <div className={classes.statistics}>
                            <div>
                                <Typography>{t("last visited")}</Typography>
                                <div>
                                    <MyChip size="small"  {...statisticData.lastaccesstime} />
                                </div>
                            </div>
                            <div>
                                <Typography>{t("creator")}</Typography>
                                <div>
                                    <MyChip size="small" color="primary" {...statisticData.name}></MyChip>
                                </div>
                            </div>
                            <div>
                                <Typography>{t("creationtime")}</Typography>
                                <div>
                                    <MyChip size="small" {...statisticData.creationtime} />
                                </div>
                            </div>
                            <div>
                                <Typography>{t("used by")}</Typography>
                                <ListUsers {...statisticData.users} />
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className={classes.endline}>
                    <TextField fullWidth multiline rowsMax="2" disabled={true} size="small" variant="outlined" value={fullLink}
                        InputProps={{
                            endAdornment:
                                <FileCopyIcon className={classes.clickIcons} onClick={copyAndSnackbar} />
                        }} />
                    <Button variant="contained" color="primary" component={Link} to={link}>
                        {t("display")}
                    </Button>
                </div>
                <MySnackbar displayMessage={snackbar.displayMessage} open={snackbar.open} onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} />
            </div>
        </div>
    );
}


export default OverviewListHeader;
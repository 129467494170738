import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useFirebaseApp } from 'reactfire';
import Alert from '@material-ui/lab/Alert';
import { Collapse, Tooltip } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
}))(Tooltip);

export default function SignIn() {

  // TODO: Redo signin, profile, signout etc

  const classes = useStyles();
  const { t } = useTranslation();
  const firebase = useFirebaseApp();
  const history = useHistory();

  // Copyright disclaimer function
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" component={RouterLink} to="/">
          {t("ShareLists")}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  // User State
  const [user, setUser] = useState({
    email: '',
    password: '',
    error: '',
    remember: true,
  });

  // save link when routed to this component (if one wants to access a list directly without beeing logged in)
  const [link, setLink] = useState("/overview")
  useEffect(() => {
    if(history.location.pathname !== "/signin"){
      setLink(history.location.pathname)
    }
    // eslint-disable-next-line
  },[])

  // Remember me user from local storage
  useEffect(() => {
    if (user.remember) {
      firebase.auth().setPersistence("local");
    } else {
      firebase.auth().setPersistence("session");
    }

    // eslint-disable-next-line
  }, [user.remember]);

  // onChange function
  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
      error: '',
    })
  };

  // onChange function for checkbox
  const handleCheckbox = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.checked,
      error: '',
    })
  };

  // Submit function (Log in user)
  const handleSubmit = e => {
    e.preventDefault();
    // Log in code here.
    signin();
  };

  const signin = (() => {
    firebase.auth().signInWithEmailAndPassword(user.email, user.password)
      .then(result => {
        if (!result.user.emailVerified) {
          setUser({
            ...user,
            error: t("verify email"),
          })
          firebase.auth().signOut();
        } else {
          history.push(link);
        }
      })
      .catch(error => {
        // Update the error
        setUser({
          ...user,
          error: error.message,
        })
      })
  });

  const signinAnon = (() => {
    firebase.auth().signInAnonymously().then(result => {
      history.push(link);
    }).catch(error => {
      setUser({
        ...user,
        error: error.message,
      })
    })
  })

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("signin")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <FormControlLabel
            control={<Checkbox name="remember" checked={user.remember} color="primary" onChange={handleCheckbox} />}
            label={t("remember me")}
          />
          <Collapse in={user.error}>
            <Alert variant="filled" severity="error">{user.error}</Alert>
          </Collapse>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("signin")}
          </Button>
          <LightTooltip placement="top" title={
            <Typography variant="body1">{t("anonymous login description")}</Typography>
          }>
            <Button
              type="button"
              onClick={() => signinAnon()}
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              {t("signin anonymously")}
            </Button>
          </LightTooltip>
          <Grid container>
            <Grid item xs>
              <Link variant="body2" component={RouterLink} to="/forgotpw">
                {t("forgot password?")}
              </Link>
            </Grid>
            <Grid item>
              <Link variant="body2" component={RouterLink} to="/signup">
                {t("no account")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
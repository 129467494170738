import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    image: {
        width: "80%"
    },

    imageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    textContainer: {
        display: "flex",
        alignItems: "center"
    },

    marginBottom: {
        marginBottom: "10px"
    }
}));

const FeatureBlock = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isVerySmall  = useMediaQuery(theme.breakpoints.down('xs'))

    const text =
        <Grid className={classes.textContainer} item xs={12} sm={6}>
            <div className = {isVerySmall ? classes.marginBottom : null}>
                <Typography variant="h4" gutterBottom>{props.header}</Typography>
                <Typography variant="body1" align="justify" gutterBottom>{props.text} {props.text2}</Typography>
            </div>
        </Grid>

    const image =
        <Grid item xs={12} sm={6} className={classes.imageContainer}>
            <img alt="  " className={classes.image} src={props.image}></img>
        </Grid>

    

    return (
        <div style={{marginBottom: "50px"}}>
            <Grid container>
            {(props.reverse || isVerySmall) 
            ?
            <Grid container>
                {text}
                {image}
            </Grid> 
            :
            <Grid container>
                {image}
                {text}
            </Grid>
            }

            </Grid>
        </div>
    );
}

export default FeatureBlock;

import { Container, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useWindowSize } from "Globals/Functions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirestoreCollectionData, useFirestore, useFirestoreDocData, useUser } from "reactfire";
import Bouncer from "../Bouncer/Bouncer";
import Createitem from "./Components/Createitem";
import Itemheader from "./Components/Itemheader";
import Items from "./Components/Items";
import NotAvailable from "./Components/NotAvailable";

const useStyles = makeStyles(theme => ({
    fullscreen: {
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        rowGap: "8px",
        margin: "8px 0px"

    },

    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const Lists = () => {

    const classes = useStyles();
    const { data: user } = useUser();
    const firestore = useFirestore();
    const fieldValue = useFirestore.FieldValue;
    const theme = useTheme();
    const isVerySmall = useMediaQuery(theme.breakpoints.down('xs'))
    const size = useWindowSize();
    const hideSize = 500; // hide stuff when height is smaller than this

    // grabs list id as following after /lists/ in url 
    const { listId } = useParams();

    // firebase collection stuff
    const collection_lists = useFirestore().collection("lists");
    const doc_lists_id = collection_lists.doc(listId);
    const collection_lists_id_items = doc_lists_id.collection("items");

    // firebase collection stuff that needs user id
    const collection_users_user_used = useFirestore().collection("users").doc(user.uid).collection("used");
    const doc_users_user_used_id = collection_users_user_used.doc(listId);

    // data objects
    const user_and_list_specific = useFirestoreDocData(collection_users_user_used.doc(listId), { idField: 'id' })
    const list_specific = useFirestoreDocData(doc_lists_id, { idField: 'id' });
    const items_of_list = useFirestoreCollectionData(collection_lists_id_items.orderBy("creationtime", "desc"), { idField: 'id' });

    // extraction of properties that will be passed to components
    const { data: data_users_user_used_id, status: data_users_user_used_id_status } = user_and_list_specific
    const { data: data_lists_id_items, status: data_lists_id_items_status } = items_of_list
    const { data: data_lists_id, status: data_lists_id_status } = list_specific

    // bundling of firebase data for passing to components
    const props = {
        collection_lists,
        doc_lists_id,
        collection_users_user_used,
        doc_users_user_used_id,
        collection_lists_id_items,
        data_users_user_used_id,
        data_lists_id_items,
        data_lists_id,
        data_lists_id_status,
        data_lists_id_items_status,
        data_users_user_used_id_status,
        hideSize
    }

    // data ready handling, true when all 3 datasets are returned
    const [dataReady, setDataReady] = useState(false);
    useEffect(() => {
        if (data_users_user_used_id_status === "success" && data_lists_id_items_status === "success" && data_lists_id_status === "success") {
            if(data_lists_id && data_lists_id.users){

                setDataReady(true)
            }
        }
    }, [data_users_user_used_id_status, data_lists_id_items_status, data_lists_id_status])

    // add to user lists and update lists data with new user
    useEffect(() => {
        if (dataReady) {

            let batch = firestore.batch();

            // check if there are users of this list
            if (data_lists_id.users.length > 0) {

                // get the user ids 
                let uids = data_lists_id.users
                uids = uids.map((user) => user.uid)

                // check if current user id is in uids
                if (uids.indexOf(user.uid) === -1) {
                    batch.update(collection_lists.doc(listId),
                        {
                            users: [...data_lists_id.users, { displayName: user.displayName, uid: user.uid }]
                        })
                }
            }

            batch.set(collection_users_user_used.doc(listId),
                {
                    listid: listId,
                    creationtime: data_lists_id.creationtime,
                    title: data_lists_id.title,
                    description: data_lists_id.description,
                    users: [...data_lists_id.users, { displayName: user.displayName, uid: user.uid }],
                    lastaccesstime: fieldValue.serverTimestamp()
                });

            batch.commit()

        }
        // eslint-disable-next-line 
    }, [dataReady]); // might cause problems

    return (
        <Container>
            {(dataReady) ?
                <div className={classes.fullscreen}
                    style={
                        {
                            height: isVerySmall ? size.height - 16 : size.height - 64 - 16,
                            gridTemplateRows: size.height > hideSize ? "auto 1fr auto" : "1fr auto"
                        }}>
                    <div style={size.height > hideSize ? null : {display: "none"}}> 
                        <Itemheader
                            {...props}
                        />
                    </div>
                    <Items
                        {...props}
                    />
                    <Createitem
                        {...props}
                    />
                </div> : null
            }
            {(!data_lists_id || !data_lists_id_items) ?
                <Bouncer /> : null
            }
            {(data_lists_id && !data_lists_id.title) ?
                <NotAvailable /> : null
            }
        </Container>

    );
}

export default Lists;
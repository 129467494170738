import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Typography, Toolbar, Container, Hidden, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import landingBackground from "img/landingNav.svg";
import { useState } from 'react';
import { useUser } from 'reactfire';
import LandingNavbar from 'Home/Subcomponents/LandingNavbar';



const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: `url(${landingBackground})`,
        backgroundPosition: "top",
        backgroundSize: "100% 1219px",
    },

    title: {
        flexGrow: 1
    },

    link: {
        color: "inherit"
    },

    drawerContent: {
        minWidth: "200px",
    },

});

const Navbar = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { data: user } = useUser();
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const theme = useTheme();
    const isVerySmall  = useMediaQuery(theme.breakpoints.down('xs'))

    const toggleDrawer = (event) => {
        setOpen(!open);
    }

    // Set navbar content depending on matched path
    let content = null
    console.log(location.pathname)
    if (location.pathname === "/") {
        content =
            <LandingNavbar toggleDrawer={toggleDrawer} />
    } else if (location.pathname.startsWith("/lists") && isVerySmall) {
        content = null
        console.log(location.pathname)
    }  else {
        content =
            <AppBar position="static" className={classes.root}>
                <Container>
                    <Toolbar>
                        <Typography variant="h4" className={classes.title}>{t("ShareLists")}</Typography>
                        <Hidden xsDown>
                            <Button className={classes.link} component={Link} to="/">{t("home")}</Button>
                            <Button className={classes.link} component={Link} to="/overview">{t("overview")}</Button>
                            <Button className={classes.link} component={Link} to="/profile">{user ? t("profile") : t("login")}</Button>
                        </Hidden>
                        <Hidden smUp>
                            <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer}>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    </Toolbar>
                </Container>
            </AppBar>
    }

    return (
        <div>
            {content}
            <Drawer anchor="right" open={open} onClose={toggleDrawer}>
                <div className={classes.drawerContent} onClick={toggleDrawer}>
                    <List>
                        <ListItem button key="home" component={Link} to="/">
                            <ListItemText>{t("home")}</ListItemText>
                        </ListItem>
                        <ListItem button key="overview" component={Link} to="/overview">
                            <ListItemText>{t("overview")}</ListItemText>
                        </ListItem>
                        <ListItem button key="signup" component={Link} to="/profile">
                            <ListItemText>{user ? t("profile") : t("login")}</ListItemText>
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

export default Navbar;
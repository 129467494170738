import Clipboard from "@react-native-clipboard/clipboard";
import { useEffect, useState } from "react";

// return UTC timestamp as human readable date in local time
const firebaseDateTimeDisplay = (timestamp) => {
    let display = " ";

    if (timestamp) {

        let date = convertFirestoreToLocal(timestamp);
        let minutes = date.getMinutes().toString();
        let hours = date.getHours().toString();
        let month = date.getMonth();
        let day = date.getDate().toString();
        if (minutes.length === 1) {
            minutes = "0" + minutes;
        }
        if (hours.length === 1) {
            hours = "0" + hours;
        }
        month += 1
        if (month < 10) {
            month = month.toString()
            month = "0" + month;
        } else {
            month = month.toString()
        }
        if (day.length === 1) {
            day = "0" + day;
        }
        display = `${day}.${month}.${date.getFullYear()} ${hours}:${minutes}`;
    }
    return display;
};

// return time and age together with color
export const firebaseHowManyDaysAgo = (timestamp, t) => {

    let now = new Date();
    let colors = ["#2196f3", "#4caf50", "#72a62a", "#939b00", "#b08c00", "#cb7900", "#e26210", "#f44336"]  // green to red

    // if data has no timestamp from firebase yet put uploading
    if (!timestamp) {
        return { text: t("hmdag_uploading"), color: colors[0], time: t("hmdag_uploading") }
    }

    let then = convertFirestoreToLocal(timestamp);
    let time = firebaseDateTimeDisplay(timestamp);

    let oneMinute = new Date(now - 60 * 1000);
    let oneHour = new Date(now - 60 * 60 * 1000);
    let oneDay = new Date(now - 24 * 60 * 60 * 1000);
    let oneWeek = new Date(now - 7 * 24 * 60 * 60 * 1000);
    let oneMonth = new Date(now - 30 * 24 * 60 * 60 * 1000);
    let oneYear = new Date(now - 365 * 24 * 60 * 60 * 1000);

    if (then > oneMinute) {
        return { text: t("hmdag_now"), color: colors[1], time: time }
    }

    else if (then > oneHour) {
        let number = Math.floor((now - then) / (60 * 1000))
        if (number === 1) {
            return { text: t("hmdag_minute", { num: number }), color: colors[2], time: time }
        }
        return { text: t("hmdag_minutes", { num: number }), color: colors[2], time: time }
    }

    else if (then > oneDay) {
        let number = Math.floor((now - then) / (60 * 60 * 1000))
        if (number === 1) {
            return { text: t("hmdag_hour", { num: number }), color: colors[3], time: time }
        }
        return { text: t("hmdag_hours", { num: number }), color: colors[3], time: time }
    }

    else if (then > oneWeek) {
        let number = Math.floor((now - then) / (24 * 60 * 60 * 1000))
        if (number === 1) {
            return { text: t("hmdag_day", { num: number }), color: colors[4], time: time }
        }
        return { text: t("hmdag_days", { num: number }), color: colors[4], time: time }
    }

    else if (then > oneMonth) {
        let number = Math.floor((now - then) / (7 * 24 * 60 * 60 * 1000))
        if (number === 1) {
            return { text: t("hmdag_week", { num: number }), color: colors[5], time: time }
        }
        return { text: t("hmdag_weeks", { num: number }), color: colors[5], time: time }
    }

    else if (then > oneYear) {
        let number = Math.floor((now - then) / (30 * 24 * 60 * 60 * 1000))
        if (number === 1) {
            return { text: t("hmdag_month", { num: number }), color: colors[6], time: time }
        }
        return { text: t("hmdag_months", { num: number }), color: colors[6], time: time }
    }

    else {
        let number = Math.floor((now - then) / (365 * 24 * 60 * 60 * 1000))
        if (number === 1) {
            return { text: t("hmdag_year", { num: number }), color: colors[7], time: time }
        }
        return { text: t("hmdag_years", { num: number }), color: colors[7], time: time }
    }
};

export const uniqueValues = (array, keyString) => {

    if (keyString) {
        array = array.map((item) => item[keyString])
    }

    let unique = array.filter((item, pos) => {
        return array.indexOf(item) === pos
    })

    return unique
};

// convert firebase server timestamp (UTC) to local time
const convertFirestoreToLocal = (timestamp) => {
    let now = new Date();
    let timezone = now.getTimezoneOffset();
    let thenMilliseconds = timestamp.toMillis();
    let then = new Date(thenMilliseconds - timezone * 60 * 1000)
    return then
};

// Copy all displayed items to clipboard
export const copyToClipboard = (input) => {
    if (Array.isArray(input)) {
        input = input.join(",\n");
    }
    Clipboard.setString(input);
}

// window size hook
export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Typography, Toolbar, Container, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import landingBackground from "img/landingNav.svg";
import { useFirebaseApp, useUser } from 'reactfire';
import DemoItem from './DemoItem';
import { CSSTransition } from 'react-transition-group';
import "Animations.css";
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        background: `url(${landingBackground})`,
        boxShadow: "None",
        height: `calc(2/3 * ${window.innerHeight}px)`,

        [theme.breakpoints.down("xs")]: {
            minHeight: `calc(${window.innerHeight}px)`,
            backgroundSize: "1862px 100%"
        }
    },

    container: {
        height: "100%"
    },

    title: {
        flexGrow: 1
    },

    link: {
        color: "inherit"
    },

    drawerContent: {
        minWidth: "200px",
    },

    outerContent: {
        height: "100%",
        position: "relative"
    },

    navbarContent: {
        position: "relative",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gap: theme.spacing(10),
        top: "50%",
        transform: "translateY(-50%)",

        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: "1fr"
        }
    },

    landingText: {
        alignSelf: "center"
    }

}));

const LandingNavbar = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { data: user } = useUser();
    const { toggleDrawer } = props;
    const history = useHistory();
    const firebase = useFirebaseApp();

    const [item1, setItem1] = useState({ item: t("try it out"), link: "/overview", checked: false, show: true, login: true });
    const [item2, setItem2] = useState({ item: t("signin"), link: "/signin", checked: true, show: true });
    const [item3, setItem3] = useState({ item: t("signup"), link: "/signup", checked: false, show: true });

    const goToLink = (item, e) => {
        if(item.login){
            firebase.auth().signInAnonymously().then(result => {
                history.push(item.link)
            }) 
        } else {
            history.push(item.link);
        }
    };


    const MyToolbar =
        <Toolbar>
            <Typography variant="h4" className={classes.title}>{t("ShareLists")}</Typography>
            <Hidden xsDown>
                <Button className={classes.link} component={Link} to="/">{t("home")}</Button>
                <Button className={classes.link} component={Link} to="/overview">{t("overview")}</Button>
                <Button className={classes.link} component={Link} to="/profile">{user ? ("profile") : "Login"}</Button>
            </Hidden>
            <Hidden smUp>
                <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer}>
                    <MenuIcon />
                </IconButton>
            </Hidden>
        </Toolbar>

    const FlyInItems =
        <div>
            <CSSTransition in={item1.show} appear={true} classNames="bounceInRight" timeout={2000} onExited={goToLink.bind(this, item1)}>
                <div style={{ animationDelay: "500ms" }}>
                    <DemoItem data={item1} changeData={setItem1} />
                </div>
            </CSSTransition>
            <CSSTransition in={item2.show} appear={true} classNames="bounceInRight" timeout={2000} onExited={goToLink.bind(this, item2)}>
                <div style={{ animationDelay: "750ms" }}>
                    <DemoItem data={item2} changeData={setItem2} />
                </div>
            </CSSTransition>
            <CSSTransition in={item3.show} appear={true} classNames="bounceInRight" timeout={2000} onExited={goToLink.bind(this, item3)}>
                <div style={{ animationDelay: "1000ms" }}>
                    <DemoItem data={item3} changeData={setItem3} />
                </div>
            </CSSTransition>
        </div>

    const LandingText =
        <div className={classes.landingText}>
            <Typography variant="h3" align="center">{t("landing page intro text")}</Typography>
            <CSSTransition in={true} appear={true} classNames="jackInTheBox" timeout={5000}>
                <div style={{ animationDelay: "2500ms" }}>
                    <Typography variant="h4" align="center">{t("landing page intro subtext")}</Typography>
                </div>
            </CSSTransition>
        </div>

    return (
        <AppBar position="static" className={classes.root}>
            <Container className={classes.container}>
                {MyToolbar}
                <div className={classes.outerContent}>
                    <div className={classes.navbarContent}>
                        {LandingText}
                        {FlyInItems}
                    </div>
                </div>
            </Container>
        </AppBar>
    );
}

export default LandingNavbar;
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        columnGap: "80px",
        rowGap: "80px"
    }
}))

const FAQ = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <div className={classes.grid}>
                <div>
                    <Typography gutterBottom variant="h5">{t("faq header 1")}</Typography>
                    <Typography align="justify" variant="body1">{t("faq body 1")}</Typography>
                </div>
                <div>
                    <Typography gutterBottom variant="h5">{t("faq header 2")}</Typography>
                    <Typography align="justify" variant="body1">{t("faq body 2")}</Typography>
                </div>
                <div>
                    <Typography gutterBottom variant="h5">{t("faq header 3")}</Typography>
                    <Typography align="justify" variant="body1">{t("faq body 3")}</Typography>
                </div>
                <div>
                    <Typography gutterBottom variant="h5">{t("faq header 4")}</Typography>
                    <Typography align="justify" variant="body1">{t("faq body 4")}</Typography>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
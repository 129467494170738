import { Typography, Chip, TextField } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ShareIcon from 'img/ShareIcon.svg';
import CopyIcon from 'img/CopyIcon.svg';
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        rowGap: "50px",
        columnGap: theme.spacing(10)
    },

    card: {
        marginLeft: "0",
        margin: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",

    },

    itemCenterContent: {
        display: "flex",
        alignItems: "center",
    },

    chip: {
        marginRight: theme.spacing(4)
    },

    spacer: {
        height: theme.spacing(5)
    },

    svgPic: {
        margin: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    svgSource: {
        height: "100px"
    },

    svgIcon: {
        fontSize: "100px",
        color: "#212121"
    },

    spread: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },

    multilineRoot: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        position: "relative",
        margin: "0"
    },

    multilineButton: {
        position: "absolute",
        width: "auto",
        height: "80%",
        top: "50%",
        right: "8px",
        transform: "translateY(-50%)"
    }
}));

const DemoTextField = withStyles(theme => ({
    root: {
        "& .MuiFormLabel-root": {
            color: theme.palette.primary.main
        },

        "& .MuiFilledInput-root": {
            backgroundColor: "white"
        },

        "& .MuiFilledInput-underline:before": {
            borderBottom: "2px solid",
            borderBottomColor: theme.palette.primary.main
        },
        "& .MuiInputBase-input": {
            color: theme.palette.text.primary
        }
    }
}))(TextField);



const FeatureColumn = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const itemChip = (text, tag) => (
        <Card className={classes.card}>
            <Checkbox color="secondary" checkedIcon={<Check />} disableRipple />
            <div className={classes.itemCenterContent}>
                <Typography>{text}</Typography>
            </div>
            {tag !== ""
                ?
                <div className={classes.itemCenterContent}>
                    <Chip className={classes.chip} label={tag} color="primary" size="small"></Chip>
                </div>
                :
                null
            }
        </Card>
    );

    const sharePic =
        <div className={classes.svgPic}>
            <img alt="" className={classes.svgSource} src={ShareIcon} />
        </div>

    const copyPic =
        <div className={classes.svgPic}>
            <img  alt="" className={classes.svgSource} src={CopyIcon} />
        </div>

    const MultiLine =
        <Card className={classes.multilineRoot}>
            <DemoTextField variant="filled" multiline disabled fullWidth value={t("feature4 content")} label={t("item title")}></DemoTextField>
            <Button className={classes.multilineButton} variant="contained" color="primary">{t("item")}</Button>
        </Card>



    return (
        <div className={classes.root}>
            <div className={classes.spread}>
                <div>
                    <Typography variant="h4" gutterBottom>{t("feature1 header")}</Typography>
                    <Typography variant="body1" align="justify">{t("feature1 body")}</Typography>
                </div>

                <div className={classes.spacer}></div>
                <div>
                    {sharePic}
                </div>

            </div>
            <div className={classes.spread}>
                <div>
                    <Typography variant="h4" gutterBottom> {t("feature2 header")}</Typography>
                    <Typography variant="body1">{t("feature2 body")}</Typography>
                </div>
                <div className={classes.spacer}></div>
                <div>
                    {itemChip(t("feature2 item1"), t("feature2 item1 tag"))}
                    {itemChip(t("feature2 item2"), t("feature2 item2 tag"))}
                </div>
            </div>
            <div className={classes.spread}>
                <div>
                    <Typography variant="h4" gutterBottom> {t("feature3 header")}</Typography>
                    <Typography variant="body1">{t("feature3 body")}</Typography>
                </div>
                <div className={classes.spacer}></div>
                <div>
                    {copyPic}
                </div>
            </div>
            <div className={classes.spread}>
                <div>
                    <Typography variant="h4" gutterBottom>{t("feature4 header")}</Typography>
                    <Typography variant="body1">{t("feature4 body")}</Typography>
                </div>
                <div className={classes.spacer}></div>
                <div>
                    {MultiLine}
                </div>
            </div>
        </div>
    );
}

export default FeatureColumn;
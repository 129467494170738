import { Button, makeStyles, Menu, useTheme, useMediaQuery } from "@material-ui/core";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, useGridSlotComponentProps } from "@material-ui/data-grid";
import { Check, Clear,FileCopy, Menu as MenuIcon } from "@material-ui/icons";
import { MySnackbar } from "Globals/Components";
import { copyToClipboard, useWindowSize } from "Globals/Functions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        width: "100%",
        alignItems: "center"
    },

})

export const MyToolbar = (props) => {
    const classes = useStyles();
    const size = useWindowSize(); // hide toolbar if screen too small
    const hideSize = props.hideSize
    
    // TODO: give different tabs for history and saved items

    return (
        <GridToolbarContainer>
            <div className={classes.root} style={size.height > hideSize ? null : {display: "none"}}>
                <div>
                    <MyClearAllButton onClick={props.clickButton1}/>
                    <MyClearCheckedButton onClick={props.clickButton2}/>
                </div>
                <div>
                    <MyDefaultButtonsMenu {...props} />
                </div>
            </div>
        </GridToolbarContainer>
    );
};


const MyDefaultButtonsMenu = (props) => {


    
    const theme = useTheme();
    const isVerySmall  = useMediaQuery(theme.breakpoints.down('xs'))
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <div>

            <Button size="small" color="primary" startIcon={!isVerySmall ? <MenuIcon /> : null} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {!isVerySmall ? t("items more") : < MenuIcon fontSize="small"/>}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <MyCopyButton/>
            </Menu>
        </div>
    )

}

// Functionality passed from props
const MyClearAllButton = (props) => {

    const theme = useTheme();
    const isVerySmall  = useMediaQuery(theme.breakpoints.down('xs'))
    const {t} = useTranslation()

    return (
        <Button startIcon={!isVerySmall ? < Clear /> :null} size="small" color="primary" onClick={props.onClick}>
            {!isVerySmall ? t("items clear all") : < Clear fontSize="small" /> }
        </Button>
    )
}


// Functionality passed from props
const MyClearCheckedButton = (props) => {

    const theme = useTheme();
    const isVerySmall  = useMediaQuery(theme.breakpoints.down('xs'))
    const {t} = useTranslation()

    return (
        <Button startIcon={!isVerySmall ? < Check /> :null} size="small" color="primary" onClick={props.onClick}>
            {!isVerySmall ? t("items clear checked") : < Check fontSize="small"/>}
        </Button>
    )
}


const MyCopyButton = (props) => {

    const {t} = useTranslation()
    const {rows} = useGridSlotComponentProps()

    const [snackbar, setSnackbar] = useState({open: false, displayMessage: "", severity: "success"})

    const copy = () => {
        copyToClipboard(rows.map(row => row.item))
        setSnackbar({open: true, displayMessage: t("items copied"), severity: "success"})
    }

    return (
        <Button startIcon={< FileCopy />} size="small" color="primary" onClick={copy}>
            {t("items copy")}
            <MySnackbar displayMessage={snackbar.displayMessage} open={snackbar.open} onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity} />
        </Button>
    )
}
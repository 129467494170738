import { useMediaQuery } from '@material-ui/core';
import { Container, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { useFirestore, useUser, useFirestoreCollectionData } from 'reactfire';
import Bouncer from "../Bouncer/Bouncer";
import Createlist from './Components/Createlist';
import InfoBox from './Components/InfoBox';
import Usedlists from './Components/Usedlists';

const useStyles = makeStyles(theme => ({
    mainGrid: {
        height: `calc(${window.innerHeight}px - 64px - 16px)`,
        margin: "8px 0px",

        display: "grid",
        rowGap: "8px",
        columnGap: "8px",

        gridTemplateRows: "1fr auto",

        [theme.breakpoints.down("xs")]: {
            height: `calc(${window.innerHeight}px - 56px - 16px)`,
        }
    },

    bottomGrid: {
        display: "grid",
        columnGap: "8px",
        gridTemplateColumns: "3fr 1fr"
    }

}));

const Overview = () => {

    const classes = useStyles();
    const { data: user } = useUser();
    const theme = useTheme();
    const isVerySmall = useMediaQuery(theme.breakpoints.down('xs'))
    // const size = useWindowSize()

    const collection_lists = useFirestore().collection("lists");
    const collection_users_user_used = useFirestore().collection("users").doc(user.uid).collection("used");
    const { data: data_users_user_used, status: data_users_user_used_status } = useFirestoreCollectionData(collection_users_user_used.orderBy("lastaccesstime", "desc"), { idField: 'id' });

    const props = { collection_lists, collection_users_user_used, data_users_user_used, data_users_user_used_status }


    const BottomPart = (props) => {
        if (isVerySmall) {
            return (
                <div>
                    <Createlist {...props} />
                </div>
            )
        } else {
            return (
                <div className={classes.bottomGrid}>
                    <div>
                        <Createlist {...props} />
                    </div>
                    <div>
                        <InfoBox />
                    </div>
                </div>
            )
        }
    }


    return (
        <Container>
            {data_users_user_used ?
                <div className={classes.mainGrid}
                    >

                    <div style={{ overflowY: "auto" }}>
                        <Usedlists  {...props} />
                    </div>

                    <BottomPart {...props} />

                </div>
                :
                <Bouncer />
            }
        </Container>
    );
}

export default Overview;
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useFirebaseApp } from 'reactfire';
import Alert from '@material-ui/lab/Alert';
import { Collapse } from '@material-ui/core';
import { MyCopyright } from 'Globals/Components';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {

    const classes = useStyles();
    const { t } = useTranslation();
    const firebase = useFirebaseApp();



    // User State
    const [user, setUser] = useState({
        email: '',
        password: '',
        nickname: '',
        error: '',
    });

    // onChange function
    const handleChange = e => {
        console.log(user)
        setUser({
            ...user,
            [e.target.name]: e.target.value,
            error: '',
            verifyEmail: ''
        })
    };

    // Submit function (Log in user)
    const handleSubmit = e => {
        e.preventDefault();
        // Log in code here.
        firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
            .then(result => {
                result.user.updateProfile({
                    displayName: user.nickname,
                }); // URL of my website.
                const myURL = { url: 'http://sharelists.app/signin' }

                // Send Email Verification and redirect to my website.
                result.user.sendEmailVerification(myURL)
                    .then(() => {
                        setUser({
                            ...user,
                            verifyEmail: `Welcome ${user.nickname}. To continue please verify your email.`,
                        })
                    })
                    .catch(error => {
                        setUser({
                            ...user,
                            error: error.message,
                        })
                    })
                // Sign Out the user.
                firebase.auth().signOut();
            }).catch(error => {
                // Update the error
                setUser({
                    ...user,
                    error: error.message,
                })
            })
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("signup")}
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label={t("name")}
                        name="nickname"
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label={t("email")}
                        name="email"
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t("password")}
                        type="password"
                        onChange={handleChange}
                    />
                    <Collapse in={user.error}>
                        <Alert variant="filled" severity="error">{user.error}</Alert>
                    </Collapse>
                    <Collapse in={user.verifyEmail}>
                        <Alert variant="filled" severity="success">{user.verifyEmail}</Alert>
                    </Collapse>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {t("signup")}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link variant="body2" component={RouterLink} to="/forgotpw">
                                {t("forgot password?")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link variant="body2" component={RouterLink} to="/signin">
                                {t("have account")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <MyCopyright />
            </Box>
        </Container>
    );
}
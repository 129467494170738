import { Button, CardContent, ListItemIcon, makeStyles, Menu, MenuItem, Snackbar, Typography } from "@material-ui/core";
import { MoreVert, NavigateBefore, Share } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { MyPaper } from "Globals/Components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    cardRoot: {
        marginBottom: "0px"
    },

    titleRow: {
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
    }
}));

const Itemheader = (props) => {

    const { data_lists_id} = props

    const { t } = useTranslation();
    const classes = useStyles();
    const shareUrl = window.location.origin + "/lists/" + data_lists_id.id;

    // share
    const shareClick = () => {
        navigator.share({ text: data_lists_id.desc, url: shareUrl, title: data_lists_id.title });
        handleClose();
    }



    // display snackbar with message
    const [snackState, setSnackState] = useState();
    const displaySnackbar = () => {
        setSnackState(!snackState)
    }
    const snackbarCopied = 
        <Snackbar open={snackState} autoHideDuration={6000} onClose={displaySnackbar} anchorOrigin={ {vertical: 'bottom', horizontal: 'center' }}>
            <Alert variant="filled" onClose={displaySnackbar}>
                {t("itemheader items_copied")}
            </Alert>
        </Snackbar>

    // Scroll Anchor handling
    const [anchorEl, setAnchorEl] = useState();
    const openItemMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const ItemheaderOptions = () => {
        return (
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={shareClick}>
                    <ListItemIcon>
                        <Share fontSize="small" />
                    </ListItemIcon>
                    <Typography>
                        {t("share")}
                    </Typography>
                </MenuItem>
            </Menu>
        )
    }

    return (
        <div>
            <MyPaper className={classes.cardRoot}>
                <div className={classes.titleRow}>
                    <Button component={Link} to={"/overview"}>
                        <NavigateBefore fontSize="large" />
                    </Button>
                    <CardContent>
                        <Typography variant="h5">{data_lists_id.title}</Typography>
                        <Typography>{data_lists_id.description}</Typography>
                    </CardContent>
                    <Button onClick={openItemMenu}>
                        <MoreVert fontSize="large" />
                    </Button>
                </div>
            </MyPaper>
            <ItemheaderOptions />
            {snackbarCopied}

        </div>
    );
}

export default Itemheader;
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@material-ui/lab";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from "@material-ui/styles";



const BetterFabDial = withStyles((theme) => ({
    fab: {
        boxShadow: "None !important",
        backgroundColor: "Transparent !important",
        color: "#9e9e9e",
    }
}))(SpeedDial);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }

}))

const MySpeedDial = (props) => {

    const {actions} = props

    const classes = useStyles();
    const [open, setOpen] = useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div className={classes.root}>
            <BetterFabDial
                ariaLabel="SpeedDial example"
                className={classes.speedDial}
                icon={<SpeedDialIcon classes={{root: classes.root}} icon={<SettingsIcon fontSize="large" />} />}
                FabProps={{disableFocusRipple: true, disableRipple: true, disableTouchRipple: true}}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="left"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipPlacement="bottom"
                        onClick={action.onClick}
                    />
                ))}
            </BetterFabDial>
        </div>
    )

}

export default MySpeedDial